
export const EXPENSE_CD_TYPES = ['01', '02', '03', '05', '07', '08'];

export const TISS_TABLE_OPTIONS = [
    { "label": "TUSS (00) - Tabela própria das operadoras", "value": "00" },
    { "label": "TUSS (18) - Diárias, taxas e gases medicinais", "value": "18" },
    { "label": "TUSS (19) - Materiais e Órteses, Próteses e Materiais Especiais (OPME)", "value": "19" },
    { "label": "TUSS (20) - Medicamentos", "value": "20" },
    { "label": "TUSS (22) - Procedimentos e eventos em saúde", "value": "22" },
    { "label": "TUSS (98) - Tabela de pacotes", "value": "98" }
];

export const parseCDType = cd => {
    switch (cd) {
        case '01':
        case '05':
        case '07':
            return 'Taxas, diarias e gases';
        case '02':
            return 'Medicamentos';
        case '03':
        case '08':
            return 'Materiais';
        default:
            return cd;
    }
}

export const parseTUSSTable = tableNumber => {
    const table = TISS_TABLE_OPTIONS.find(table => table.value === tableNumber);
    return table ? table.label : tableNumber;
}

